import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Import Router, Routes, and Route
import StartScreen from "./components/SplashScreen/index"; // Import the StartScreen component
import QuizComponent from "./components/Main/index"; // Import your quiz component
import Result from "./components/Result";
import "./App.css";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<QuizComponent />} />
        <Route path="/showResult/:category" element={<Result />} />
      </Routes>
    </Router>
  );
};

export default App;
