export const SendUserDataToSheet = async (data: any) => {
  try {
    // Use empty string for relative paths
    // const response = await fetch(`/api/submit-data`, {
    // const response = await fetch(`http://quiz.future-finance.co.za:7001/api/submit-data`, {
    const response = await fetch(`https://quiz.future-finance.co.za/api/submit-data`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    return response;
  } catch (error) { }
};
