interface Props {
  currentQuestion: number;
  totalQuestions: number;
  progressWidth: number;
}

const ProgressBar = ({
  currentQuestion,
  totalQuestions,
  progressWidth,
}: Props) => {
  // Ensure progress is capped at 100%
  const progressPercentage = Math.min(
    (progressWidth / totalQuestions) * 100,
    100
  );

  return (
    <div className="mb-6">
      <div className="relative w-full h-4 bg-[#6F6D6A] rounded-full overflow-hidden shadow-sm">
        <div
          className="absolute h-full bg-[#FF9C33] transition-all duration-300"
          style={{ width: `${progressPercentage}%` }}
        ></div>
      </div>

      <div className="text-center mt-2 text-sm text-gray-700 font-medium">
        Question {currentQuestion + 1} of {totalQuestions}
      </div>
    </div>
  );
};

export default ProgressBar;
