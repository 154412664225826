import { motion } from "framer-motion";
const LoadingScreen = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="flex flex-col items-center justify-center space-y-6"
    >
      <motion.div
        animate={{
          scale: [1, 1.1, 1],
          rotate: [0, 10, -10, 0],
        }}
        transition={{
          duration: 1,
          repeat: Infinity,
          ease: "easeInOut",
        }}
        className="text-6xl"
      >
        🧮
      </motion.div>
      <h2 className="text-2xl font-semibold">
        Analyzing Your Financial Profile
      </h2>
      <div className="w-64 bg-gray-200 rounded-full h-2.5">
        <motion.div
          initial={{ width: 0 }}
          animate={{ width: "100%" }}
          transition={{ duration: 5 }}
          className="bg-[#FF9C33] h-2.5 rounded-full"
        ></motion.div>
      </div>
      <p className="text-gray-600">This may take a moment...</p>
    </motion.div>
  );
};

export default LoadingScreen;
