import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import type { CategoryType } from "../../types";
import ResultSection from "./ResultSection";
import Gauge from "../ui/FinancialHealthGauge";

interface Props {
  result: CategoryType | null;
}

const ResultsScreen = ({ result }: Props) => {
  const [debouncedResult, setDebouncedResult] = useState(result);

  useEffect(() => {
    const timeout = setTimeout(() => setDebouncedResult(result), 200);
    return () => clearTimeout(timeout);
  }, [result]);

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      className="text-center"
    >
      <div>
        <h1 className="text-4xl font-bold mb-4">Financial Wellness Report</h1>
        <p className="text-lg">
          Thank you for completing our financial wellness quiz. We're excited to
          provide you with insights tailored to your financial situation. This
          report is designed to guide you on your journey toward financial
          freedom.
        </p>
      </div>

      <div className="mb-8 mt-4">
        {debouncedResult && (
          <div className="flex mx-auto mt-3">
            <Gauge result={debouncedResult} />
          </div>
        )}
      </div>
      <ResultSection result={debouncedResult} />
    </motion.div>
  );
};

export default ResultsScreen;
