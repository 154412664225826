import React, { Suspense } from "react";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import type { CategoryType } from "../../types";

// Lazy load ResultsScreen
const ResultsScreen = React.lazy(() => import("../ResultScreen"));

const Result: React.FC = () => {
  const { category } = useParams<{ category?: CategoryType }>(); // Allow undefined

  const validCategory = category as CategoryType | null; // Explicitly cast or validate

  const renderContent = (result: CategoryType) => {
    switch (result) {
      case "Credit Repair":
      case "Debt Consolidation":
      case "Good Financial Standing":
        return <ResultsScreen result={result} />;
      default:
        return <p>Result no Found</p>;
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="min-h-screen flex items-center justify-center p-4"
    >
      <div
        className="bg-white shadow-2xl rounded-2xl w-full max-w-2xl p-8 
        transform transition-all duration-300 hover:scale-[1.01]"
      >
        <Suspense fallback={<p>Loading...</p>}>
          {validCategory ? (
            <div>{renderContent(validCategory)}</div>
          ) : (
            <p>No valid category provided.</p>
          )}
        </Suspense>
      </div>
    </motion.div>
  );
};

export default Result;
