import React from "react";
import GaugeChart from "react-gauge-chart";

// Define the prop types
interface FinancialHealthGaugeProps {
  result: "Credit Repair" | "Debt Consolidation" | "Good Financial Standing";
}

// Define the configuration type
interface GaugeConfig {
  [key: string]: {
    color: string;
    range: [number, number];
    label: string;
    description: string;
    value?: number;
  };
}

const FinancialHealthGauge: React.FC<FinancialHealthGaugeProps> = ({
  result,
}) => {
  const gaugeConfig: GaugeConfig = {
    "Credit Repair": {
      color: "#ef4444", // Red
      range: [0, 33.33],
      label: "Credit Repair",
      description: "Needs Immediate Attention",
    },
    "Debt Consolidation": {
      color: "#eab308", // Yellow
      range: [33.33, 66.66],
      label: "Debt Consolidation",
      description: "Requires Strategic Management",
    },
    "Good Financial Standing": {
      color: "#22c55e", // Green
      range: [66.66, 100],
      label: "Good Financial Standing",
      description: "Financially Healthy",
    },
  };

  const getCurrentConfig = () => {
    switch (result) {
      case "Credit Repair":
        return {
          ...gaugeConfig["Credit Repair"],
          value: 16.66,
        };
      case "Debt Consolidation":
        return {
          ...gaugeConfig["Debt Consolidation"],
          value: 50,
        };
      case "Good Financial Standing":
        return {
          ...gaugeConfig["Good Financial Standing"],
          value: 83.33,
        };
      default:
        return {
          ...gaugeConfig["Credit Repair"],
          value: 0,
        };
    }
  };

  const currentConfig = getCurrentConfig();

  return (
    <div className="w-full max-w-md mx-auto p-4 bg-white rounded-xl shadow-lg">
      <h3 className="text-xl font-bold text-center mb-4">
        Financial Health Indicator
      </h3>
      <GaugeChart
        id="financial-health-gauge"
        nrOfLevels={3}
        arcsLength={[0.333, 0.333, 0.334]}
        colors={["#ef4444", "#eab308", "#22c55e"]}
        percent={currentConfig.value / 100}
        arcPadding={0.05}
        needleColor={currentConfig.color}
        textColor={currentConfig.color}
        hideText={true}
      />

      <div className="text-center mt-4">
        <h4
          className="text-lg font-semibold"
          style={{ color: currentConfig.color }}
        >
          {currentConfig.label}
        </h4>
        <p className="text-gray-600 font-bold">{currentConfig.description}</p>
      </div>
    </div>
  );
};

export default FinancialHealthGauge;
