import React, { useState, useEffect } from "react";
import ProgressBar from "../ui/ProgressBar";
import LoadingScreen from "../LoadingScreen";
import ResultsScreen from "../ResultScreen";
import Logo from "../../assets/site-logo.png";
import { motion, AnimatePresence } from "framer-motion";

import { questions } from "../../data/QuizQuestions/creditRepair";
import { SendUserDataToSheet } from "../../api/sendDataToGSheet";
import type { CategoryType } from "../../types";

const CreditRepairQuiz: React.FC = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState<any[]>([]);
  const [quizStage, setQuizStage] = useState("quiz");
  const [result, setResult] = useState<CategoryType | null>(null);
  const [categoryPoints, setCategoryPoints] = useState<Record<
    CategoryType,
    number
  > | null>(null);
  const [progressWidth, setProgressWith] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const calculateResult = (): CategoryType => {
    const points: Record<CategoryType, number> = {
      "Credit Repair": 0,
      "Debt Consolidation": 0,
      "Good Financial Standing": 0,
    };

    selectedAnswers.forEach((answer) => {
      const categories = answer.category.split("/");

      categories.forEach((cat: string) => {
        const trimmedCat = cat.trim() as CategoryType;
        if (trimmedCat in points) {
          points[trimmedCat] += categories.length > 1 ? 1 : 2;
        }
      });
    });

    setCategoryPoints(points); // Save category points in state
    return (Object.keys(points) as CategoryType[]).reduce((a, b) =>
      points[a] > points[b] ? a : b
    );
  };

  const handleAnswer = (answer: any) => {
    if (quizStage !== "quiz" || isTransitioning) return; // Prevent interaction outside quiz stage

    setSelectedAnswers((prev) => [...prev, answer]);

    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
      setProgressWith((prev) => prev + 1);
    } else {
      setIsTransitioning(true); // Block interactions during transition
      const root = document.getElementById("root");
      const kajabiFormContainer = document.getElementById("kajabi-form");

      setProgressWith((prev) => prev + 1);
      setTimeout(() => {
        if (root) {
          root.style.height = "0px";
          root.style.overflow = "hidden";
          root.style.transition = "height 0.3s ease";
        }
        if (kajabiFormContainer) {
          kajabiFormContainer.style.display = "flex";
          kajabiFormContainer.style.height = "100vh";
          kajabiFormContainer.style.width = "100%";
          kajabiFormContainer.style.justifyContent = "center";
          kajabiFormContainer.style.alignItems = "center";
        }
      }, 700);

      const calculatedResult = calculateResult();
      setResult(calculatedResult);
    }
  };

  useEffect(() => {
    const handleFormSubmit = async (event: Event) => {
      event.preventDefault(); // Prevent default behavior

      setQuizStage("loading");
      setIsTransitioning(false); // Allow interactions again in loading stage
      if (isSubmitting) return; // Prevent multiple submissions
      setIsSubmitting(true);

      const form = event.target as HTMLFormElement;
      const formData = new FormData(form);

      const formValues: Record<string, string> = {};
      formData.forEach((value, key) => {
        formValues[key] = value.toString();
      });

      const calculatedResult = result || calculateResult();
      const totalScore = categoryPoints
        ? Math.max(...Object.values(categoryPoints))
        : 100;

      const userData = {
        name: formValues["form_submission[name]"],
        emailAddress: formValues["form_submission[email]"],
        answers: selectedAnswers.map((answer) => answer.text),
        creditRepairStatus: calculatedResult,
        totalScore,
      };

      try {
        // Send data to your custom API
        await SendUserDataToSheet(userData);
        formData.set(
          "form_submission[address_line_1]",
          calculatedResult.toString()
        );
        // formData[]
        // Add the result to the formData
        // formData.append(
        //   "form_submission[address_line_1]",
        //   calculatedResult.toString()
        // ); // Append result to form data

        // Send data to Kajabi endpoint
        const kajabiResponse = await fetch(
          "https://members.mycreditfix.co.za/forms/2148861814/form_submissions",
          // "https://quiz.future-finance.co.za/api/form-submission",

          {
            method: "POST",
            mode: "no-cors",
            body: formData, // Use the modified form data
            headers: {
              Accept: "application/json", // Adjust headers if needed by Kajabi
            },
          }
        );

        if (!kajabiResponse.ok) {
          throw new Error("Failed to submit data to Kajabi");
        }
      } catch (error) {
        console.error("Error during form submission:", error);
      } finally {
        setIsSubmitting(false); // Allow submission again after process completes
      }

      const root = document.getElementById("root");
      const kajabiFormContainer = document.getElementById("kajabi-form");

      if (root) {
        root.style.height = "auto";
        root.style.overflow = "visible";
        root.style.transition = "height 0.3s ease";
      }

      if (kajabiFormContainer) {
        kajabiFormContainer.style.display = "none";
        kajabiFormContainer.style.height = "0px";
      }

      setTimeout(() => setQuizStage("result"), 5000);
    };

    const kajabiForm = document.querySelector("#kajabi-form form");
    if (kajabiForm) {
      kajabiForm.addEventListener("submit", handleFormSubmit);
    } else {
      console.error("Kajabi form not found");
    }

    return () => {
      if (kajabiForm) {
        kajabiForm.removeEventListener("submit", handleFormSubmit);
      }
    };
  }, [result, selectedAnswers, isSubmitting, categoryPoints]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="min-h-screen flex items-center justify-center p-4"
    >
      <div
        className="bg-white shadow-2xl rounded-2xl w-full max-w-2xl p-8 
        transform transition-all duration-300 hover:scale-[1.01]"
      >
        <AnimatePresence mode="wait">
          {quizStage === "quiz" && (
            <motion.div
              key="question"
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              transition={{ duration: 0.3 }}
              onAnimationComplete={() => setIsTransitioning(false)}
            >
              <div className="flex flex-col justify-center items-center mb-2">
                <img src={Logo} alt="logo" width={100} height={100} />
              </div>
              <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">
                Financial Wellness Quiz 💰
              </h1>
              <ProgressBar
                progressWidth={progressWidth}
                currentQuestion={currentQuestion}
                totalQuestions={questions.length}
              />

              <motion.h2
                key={`question-title-${currentQuestion}`}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="text-xl font-semibold mb-6 text-center"
              >
                {questions[currentQuestion].question}
              </motion.h2>

              <div className="space-y-4" key={currentQuestion}>
                {questions[currentQuestion].options.map((option, index) => (
                  <motion.button
                    key={index}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={() => handleAnswer(option)}
                    disabled={isTransitioning} // Disable buttons during transition
                    className="w-full p-4 border rounded-lg text-left 
                      flex items-center justify-between
                      hover:border-[#FF9C33] 
                      transition-all duration-200 ease-in-out
                      focus:outline-none focus:ring-2 focus:ring-[#f3aa5d]"
                  >
                    <span>{option.text}</span>
                    <span className="text-2xl">{option.emoji}</span>
                  </motion.button>
                ))}
              </div>
            </motion.div>
          )}

          {quizStage === "loading" && <LoadingScreen />}
          {quizStage === "result" && <ResultsScreen result={result} />}
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

export default CreditRepairQuiz;
