import type { QuestionType } from "../../types";
export const questions: QuestionType[] = [
  {
    question:
      "Have you recently been declined for a loan, credit card, or store account?",
    options: [
      {
        text: "Yes, within the last six months",
        category: "Credit Repair",
        emoji: "😟",
        id: 1,
      },
      {
        text: "Yes, but over six months ago",
        category: "Credit Repair",
        emoji: "🕰️",
        id: 2,
      },
      {
        text: "No, I have not been declined",
        category: "Good Financial Standing",
        emoji: "😊",
        id: 3,
      },
    ],
  },
  {
    question:
      "Do you know the reasons why your credit application was declined or your credit score is low?",
    options: [
      {
        text: "No, I'm unsure why",
        category: "Credit Repair",
        emoji: "🤔",
        id: 1,
      },
      {
        text: "I have a vague idea but not the specifics",
        category: "Credit Repair",
        emoji: "🧐",
        id: 2,
      },
      {
        text: "Yes, I understand the reasons fully",
        category: "Good Financial Standing",
        emoji: "👍",
        id: 3,
      },
    ],
  },
  {
    question:
      "How often do you feel stressed or anxious about your financial situation?",
    options: [
      {
        text: "Always",
        category: "Credit Repair/Debt Consolidation",
        emoji: "😰",
        id: 1,
      },
      { text: "Sometimes", category: "Debt Consolidation", emoji: "😐", id: 2 },
      {
        text: "Rarely or never",
        category: "Good Financial Standing",
        emoji: "😌",
        id: 2,
      },
    ],
  },
  {
    question:
      "Are you currently behind on any of your loan or credit repayments?",
    options: [
      {
        text: "Yes, on multiple accounts",
        category: "Credit Repair/Debt Consolidation",
        emoji: "😱",
        id: 1,
      },
      {
        text: "Yes, on one account",
        category: "Credit Repair",
        emoji: "😓",
        id: 2,
      },
      {
        text: "No, I'm up to date on all repayments",
        category: "Good Financial Standing",
        emoji: "✅",
        id: 2,
      },
    ],
  },
  {
    question:
      "Have you ever reviewed your credit report and understood your credit score?",
    options: [
      {
        text: "No, I've never checked it",
        category: "Credit Repair",
        emoji: "🙈",
        id: 1,
      },
      {
        text: "I've checked it, but it's confusing",
        category: "Credit Repair",
        emoji: "🤯",
        id: 2,
      },
      {
        text: "Yes, and I understand it",
        category: "Good Financial Standing",
        emoji: "📊",
        id: 3,
      },
    ],
  },
  {
    question:
      "Do you feel confident in your ability to manage your debts and financial obligations?",
    options: [
      {
        text: "Not confident at all",
        category: "Credit Repair/Debt Consolidation",
        emoji: "😢",
        id: 1,
      },
      {
        text: "Somewhat confident",
        category: "Debt Consolidation",
        emoji: "🤨",
        id: 2,
      },
      {
        text: "Very confident",
        category: "Good Financial Standing",
        emoji: "💪",
        id: 3,
      },
    ],
  },
  {
    question:
      "Have you considered consolidating your debts into a single, more manageable payment?",
    options: [
      {
        text: "Yes, but I'm unsure how to proceed",
        category: "Debt Consolidation",
        emoji: "🤷",
        id: 1,
      },
      {
        text: "I've thought about it but haven't taken action",
        category: "Debt Consolidation",
        emoji: "💭",
        id: 2,
      },
      {
        text: "No, I manage my debts without consolidation",
        category: "Good Financial Standing",
        emoji: "🎯",
        id: 3,
      },
    ],
  },
  {
    question:
      "Have you used high-interest loans (like payday loans) or borrowed from informal lenders?",
    options: [
      {
        text: "Yes, multiple times",
        category: "Credit Repair/Debt Consolidation",
        emoji: "💸",
        id: 1,
      },
      {
        text: "Yes, but only once",
        category: "Debt Consolidation",
        emoji: "🚨",
        id: 2,
      },
      {
        text: "No, I've never used high-interest or informal loans",
        category: "Good Financial Standing",
        emoji: "🛑",
        id: 3,
      },
    ],
  },
  {
    question:
      "Do you have a monthly budget that you follow to manage your expenses?",
    options: [
      {
        text: "No, I don't have a budget",
        category: "Credit Repair/Debt Consolidation",
        emoji: "📉",
        id: 1,
      },
      {
        text: "I have a budget but struggle to stick to it",
        category: "Debt Consolidation",
        emoji: "😅",
        id: 2,
      },
      {
        text: "Yes, and I follow it closely",
        category: "Good Financial Standing",
        emoji: "📈",
        id: 3,
      },
    ],
  },
  {
    question:
      "Are you interested in learning more about managing your credit and improving your financial health?",
    options: [
      {
        text: "Yes, I need guidance urgently",
        category: "Credit Repair/Debt Consolidation",
        emoji: "🆘",
        id: 1,
      },
      {
        text: "Maybe, I'd like to explore my options",
        category: "Debt Consolidation",
        emoji: "🔍",
        id: 2,
      },
      {
        text: "No, I feel knowledgeable already",
        category: "Good Financial Standing",
        emoji: "🎓",
        id: 3,
      },
    ],
  },
];
