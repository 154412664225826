import React, { useState } from "react";

import StartScreen from "../SplashScreen";
import CreditRepairQuiz from "../CreditRepairQuiz/CreditRepairQuiz";
const Main: React.FC = () => {
  const [started, setStarted] = useState(false);

  const handleStart = () => {
    setStarted(true); // Show the quiz when the Start button is clicked
  };

  return (
    <div>
      {!started ? <StartScreen onStart={handleStart} /> : <CreditRepairQuiz />}
    </div>
  );
};

export default Main;
