import React from "react";

interface StartScreenProps {
  onStart: () => void;
}

const StartScreen: React.FC<StartScreenProps> = ({ onStart }) => {
  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-blue-500 to-blue-700 text-white overflow-hidden">
      <div className="absolute top-10 left-10 w-20 h-20 bg-white opacity-10 rounded-full"></div>
      <div className="absolute bottom-20 right-10 w-32 h-32 bg-white opacity-20 rounded-full"></div>

      <div className="text-center">
        <h1 className="md:text-8xl text-5xl font-medium font-montserrat mb-4 tracking-wide drop-shadow-lg">
          Money Matters Quiz
        </h1>
        <p className="md:text-3xl text-lg font-light font-montserrat opacity-90 mb-8">
          Take the first step towards understanding your financial health.
        </p>
      </div>

      <button
        onClick={onStart}
        className="px-8 py-4 text-2xl font-bold font-montserrat  w-96 rounded-xl bg-white text-blue-600 shadow-lg hover:bg-blue-100 hover:scale-105 transition-all duration-300 ease-in-out"
      >
        Get My Personalised Plan
      </button>
    </div>
  );
};

export default StartScreen;
